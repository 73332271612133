import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const HomeHowItWorks = () => {
  return (
    <>
      <div className="row mb-4">
        <div className="col">
          <h2 className="thin large">How it Works</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <div className="col">
              <div className="how-card step-1 movable-element">
                <a
                  className="card-link"
                  href="https://olex-ai.gitbook.io/olex/how-it-works/analyze-industry-developments"
                  target="_blank"
                >
                  <div className="row">
                    <div className="col-8">
                      <div className="step text-center">1</div>
                      <h5>Step 1</h5>
                      <h4>Analayze Industry Developments</h4>
                    </div>
                    <div className="col text-end">
                      <img src="how-1.svg" height="74px" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-4">
              <div className="how-card small step-1">
                <div className="row">
                  <div className="col">
                    <h4>Web3 & Crypto News</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="how-card small step-1">
                <div className="row">
                  <div className="col">
                    <h4>Overall Market Sentiment</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="how-card small step-1">
                <div className="row">
                  <div className="col">
                    <h4>Market Gap Analysis</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-4">
              <div className="how-card small step-3">
                <div className="row">
                  <div className="col">
                    <h4>Specialist Team Creation</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="how-card small step-3">
                <div className="row">
                  <div className="col">
                    <h4>Agent Objective Tracking</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="how-card small step-3">
                <div className="row">
                  <div className="col">
                    <h4>Delivery Parameter Analysis</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="how-card movable-element step-2">
            <a className="card-link" href="https://olex-ai.gitbook.io/olex/how-it-works/project-design" target="_blank">
              <div className="row">
                <div className="col">
                  <div className="step text-center">2</div>
                  <h5>Step 2</h5>
                  <h4>Project Design</h4>
                </div>
                <div className="col text-end">
                  <img src="how-2.svg" height="74px" />
                </div>
              </div>
            </a>
          </div>
          <div className="how-card step-3 movable-element mt-4">
            <a className="card-link" href="https://olex-ai.gitbook.io/olex/how-it-works/worker-agents" target="_blank">
              <div className="row">
                <div className="col">
                  <div className="step text-center">3</div>
                  <h5>Step 3</h5>
                  <h4>Worker Agents</h4>
                </div>
                <div className="col text-end">
                  <img src="how-3.svg" height="74px" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row d-none d-md-flex">
            <div className="col-4">
              <div className="how-card small step-2">
                <div className="row">
                  <div className="col">
                    <h4>Scope Project Solutions</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="how-card small step-2">
                <div className="row">
                  <div className="col">
                    <h4>
                      Route <br />
                      to
                      <br /> Market
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="how-card small step-2">
                <div className="row">
                  <div className="col">
                    <h4>Initial Project Roadmap</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="how-card movable-element step-4">
                <a
                  className="card-link"
                  href="https://olex-ai.gitbook.io/olex/how-it-works/community-management"
                  target="_blank"
                >
                  <div className="row">
                    <div className="col">
                      <div className="step text-center">4</div>
                      <h5>Step 4</h5>
                      <h4>Community Management</h4>
                    </div>
                    <div className="col text-end">
                      <img src="how-4.svg" height="90px" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-4">
              <div className="how-card small step-4">
                <div className="row">
                  <div className="col">
                    <h4>Build a User Following</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="how-card small step-4">
                <div className="row">
                  <div className="col">
                    <h4>Initiate Project Admins</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="how-card small step-4">
                <div className="row">
                  <div className="col">
                    <h4>Engage With Users</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHowItWorks;
