import React from 'react';

const HomeRoadmap = () => {
  return (
    <div className="row">
      <div className="col">
        <h2 className="thin large">Roadmap</h2>

        {/* Timeline */}
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="timeline timeline-one">
              {/* Timeline Item 1 */}
              <div className="timeline-item">
                <span className="icon icon-info icon-lg">
                  <i className="fa-regular fa-calendar"></i>
                </span>
                <h5>Q4 2024</h5>
                <ul>
                  <li>Initial Demos</li>
                  <li>Launch</li>
                  <li>CoinMarketCap Listing</li>
                  <li>Deployment of Industry Analyzer Agent</li>
                </ul>
              </div>

              {/* Timeline Item 2 */}
              <div className="timeline-item">
                <span className="icon icon-secondary">
                  <i className="fa-regular fa-calendar"></i>
                </span>
                <h5>Q1 2025</h5>
                <ul>
                  <li>CEX Listing</li>
                  <li>Deployment of Project Design Agent</li>
                  <li>Deployment of Community Management Agent</li>
                </ul>
              </div>

              {/* Timeline Item 3 */}
              <div className="timeline-item">
                <span className="icon icon-danger">
                  <i className="fa-regular fa-calendar"></i>
                </span>
                <h5>Q2 2025</h5>
                <ul>
                  <li>Deployment of Enhanced Design Agent</li>
                  <li>Release of First Olex Market Product</li>
                  <li>Real Yield Staking</li>
                  <li>Second CEX Listing</li>
                </ul>
              </div>
            </div>
            {/* End of Timeline */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeRoadmap;
