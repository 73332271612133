import React, { FC } from 'react';
import styles from './footer.module.scss';

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <footer className="section-colored">
    <div className="container">
      <div className="row">
        <div className="col-md-3 mt-3 mt-md-0">
          <h5 className="mb-1 mb-md-2">ECOSYSTEM</h5>
          <ul>
            <li>
              <a href="https://olex-ai.gitbook.io/olex/how-it-works/analyze-industry-developments" target="_blank">
                Industry Analyzer
              </a>
            </li>
            <li>
              <a href="https://olex-ai.gitbook.io/olex/how-it-works/project-design" target="_blank">
                Project Designer
              </a>
            </li>
            <li>
              <a href="https://olex-ai.gitbook.io/olex/how-it-works/worker-agents" target="_blank">
                Worker Agents
              </a>
            </li>
            <li>
              <a href="https://olex-ai.gitbook.io/olex/how-it-works/community-management" target="_blank">
                Community Manager
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-3 mt-3 mt-md-0">
          <h5 className="mb-1 mb-md-2">GET IN TOUCH</h5>
          <a href="https://x.com/iamolexai" target="_blank">
            <i className="fa fa-2x fa-brands fa-square-x-twitter me-2" />
          </a>
        </div>
        <div className="col-md-3 mt-3 mt-md-0">
          <h5 className="mb-1 mb-md-2">ABOUT</h5>
          <ul>
            <li>
              <a href="#howitworks">How it Works</a>
            </li>
            <li>
              <a href="#roadmap">Roadmap</a>
            </li>
          </ul>
        </div>
        <div className="col-md-3 mt-3 mt-md-0">
          <h5 className="mb-1 mb-md-2">TOKEN</h5>
          <ul>
            <li>Buy OLEX</li>
            <li>
              <a href="https://olex-ai.gitbook.io/olex" target="_blank">
                What is the OLEX Token?
              </a>
            </li>
            <li>
              <a href="https://olex-ai.gitbook.io/olex/tokenomics" target="_blank">
                Tokenomics
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
