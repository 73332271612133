import React from 'react';

const HomeTasks = () => {
  return (
    <>
      <h4 className="mt-5">Tasks</h4>
      <hr />
      <p>
        Everyone wants that perfect team member on their project. With over 700 trained hours and more than 900 data
        points, OLEX is positioned to not only be that team member, but to be hundreds of them.
      </p>
      <p>
        OLEX doesn't get tired, it doesn't get distracted or burnt out. It's the perfect network of team members, able
        to work 24 hours a day, 7 days a week.
      </p>
    </>
  );
};

export default HomeTasks;
