import React from 'react';

const HomeNews = () => {
  return (
    <>
      <h4 className="mt-5">Predicting the Future Using the Present</h4>
      <hr />
      <p>
        Olex scours over 60 news sources daily, looking for patterns and trends. With over 900 data points, Olex is well
        positioned to find gaps in the market, enabling it to build the right product, for the right community, at the
        right time.
      </p>
      <p>Chains, platforms, and anything else you can think of, as long as it's Crypto, Olex is interested.</p>
      <p>Imagine if your favourite projects were as up to date on trends as Olex is!</p>
    </>
  );
};

export default HomeNews;
