import React from 'react';

const HomeProducts = () => {
  return (
    <>
      <h4 className="mt-5">Products</h4>
      <hr />
      <p>
        Building a project doesn't end once the code is done. Any successful product needs marketing and a community.
      </p>
      <p>
        Trained on thousands of successful and unsuccessful projects, OLEX has had a crash course in what it takes to
        build a vibrant product and it starts putting these things into play from day one.
      </p>
      <p>
        The community doesn't sleep, so neither does OLEX. OLEX works around the clock to build the community and grow
        the reach of products.
      </p>
    </>
  );
};

export default HomeProducts;
